import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import {
  Section,
  SectionWrapper,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import ApplyInterFont from "../../components/campaigns/ApplyInterFont";
import { OnDesktop } from "../../components/DesktopMobileComps";
import { Button } from "../../components/core/Button";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, QuoteModalPlaceholder, HeroSectionData, HeroSectionContainer, ModalContainer, HeroSectionHeading } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const PerilsSection = loadable(() => import("../../components/campaigns/glp6/PerilsSection"))
const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewsSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp9/ReviewsSection"))
const WhyChooseVerak = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/WhyChooseVerak"))
const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/ExtraBenefits"))
const FooterSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp9/FooterSection"))

const quoteCtaProps ={
  label: "VIEW PRICES",
  uppercaseLabel: true
}

const IndexPage = () => {
  const mixpanel = useMixpanel();

  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  React.useEffect(() => {
    document.addEventListener('touchstart', () => {}, {passive: true});
  }, [])

  const heroSectionDefaultComp = (
    <>
      <StyledHeroSectionHeading>
        Insure your shop starting at just 
        <br /> 
        <span style={{color: "rgba(79, 118, 232)"}}>
          ₹8 per day!
        </span>
      </StyledHeroSectionHeading>
      {!hideQuoteCTAs && (
        <OnDesktop>
          <Button 
            type="flatdesign" 
            style={{padding: "12px 20px"}} 
            label="VIEW PRICES" 
            onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} 
          />
        </OnDesktop>
      )}
      <HeroSectionSubHeading>
        Get protection from 15+ common risks like 
      </HeroSectionSubHeading>
      <PerilsSection shopInsurance />
    </>
  )

  return (
    <>
      <ApplyInterFont />
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar  
        quoteCtaProps={quoteCtaProps} 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe}
        showCallHelplineCTA={isSelfServe}  
        newTheme 
        handleGetQuoteClick={scrollToLeadForm}
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <StyledHeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "4px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <StyledHeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </StyledHeroSectionContainer>
              <StyledModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  newTheme 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen} 
                  quoteCtaProps={quoteCtaProps}
                />
              </StyledModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness mobileStyles={{background: "#F6F9FF"}} desktopStyles={{background: "#FFF"}} /> : null}
      <QuotesFromCompanies desktopStyles={{background: "#F6F9FF"}} mobileStyles={{background: "#FFF"}} />
      <ReviewsSection shopInsurance quoteCtaProps={quoteCtaProps} handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)} />
      <WhyChooseVerak showGetQuote scrollableCards quoteCtaProps={quoteCtaProps} handleGetQuoteClick={(e, ctaNumber=3) => handleGetQuoteClick(e, ctaNumber)} />
      <ExtraBenefits />
      <FooterSection quoteCtaProps={quoteCtaProps} handleGetQuoteClick={(e, ctaNumber=4) => handleGetQuoteClick(e, ctaNumber)} />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

const StyledHeroBg = styled(HeroBg)`
  background: rgba(233, 239, 254, 0.4);
`

const StyledHeroSectionContainer = styled(HeroSectionContainer)`
  @media (max-width: 768px) {
    display: ${p => p.hideHeroSection ? "none" : "block"};
  }
`

const StyledModalContainer = styled(ModalContainer)`
  width: min(520px, 100%);
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  font-family: Inter;
  font-size: 30px;
  line-height: 60px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const HeroSectionSubHeading = styled.h4`
  margin-top: 56px; 
  opacity: 0.7;
  font-size: 24px;
  line-height: 29px;
  color: #333333;
  font-weight: 600;
  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 13px;
    line-height: 16.38px;
    text-align: left;
  }
`

export default IndexPage;
